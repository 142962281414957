<template>
    <div class="master-create-form">
        <div class="flex align-center">
            <div class="flex justify-between align-center mb-2">
                <h2 class="text-uppercase font-bold">{{ this.formMode }} Coaching Center</h2>
           </div>
        </div>
        <div class="md-layout md-gutter mt-2">
            <form @submit.prevent="handleSubmit" class="w-full">
                <div class="md-layout-item md-size-100">
                    <div class="md-layout-item md-size-50 md-xsmall-size-100 inline-block mt-6">   
                        <InputFieldComponent
                            type="text"
                            :requiredLabel="true"
                            label="Business Name"
                            class="my-1 align-left"
                            v-model.trim="form.business_name"
                            :message="($v.form.business_name.$dirty && !$v.form.business_name.required) ? 'Field is required' : null || formErrors.business_name && formErrors.business_name[0]"
                            :class="{'vuelidate-error': $v.form.business_name.$error || formErrors.business_name}"
                        />
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100 inline-block mt-6">   
                        <InputFieldComponent
                            type="text"
                            :requiredLabel="true"
                            label="Email"
                            class="my-1 align-left"
                            v-model.trim="form.business_email"
                            :message="($v.form.business_email.$dirty && $v.form.business_email.$error ? 'Invalid email address' : null) || formErrors.business_email && formErrors.business_email[0]"
                            :class="{'vuelidate-error': $v.form.business_email.$error || formErrors.business_email}"
                        />
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100 inline-block mt-6">   
                        <InputFieldComponent
                            type="text"
                            :requiredLabel="true"
                            label="Phone Number"
                            class="my-1 align-left"
                            v-model.trim="form.business_contact_number"
                            :message="($v.form.business_contact_number.$dirty && !$v.form.business_contact_number.required ? 'Field is required' : null) || formErrors.business_contact_number && formErrors.business_contact_number[0]"
                            :class="{'vuelidate-error': $v.form.business_contact_number.$error || formErrors.business_contact_number}"
                        />
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100 inline-block mt-6">  
                        <div class="flex align-center website-address"> 
                            <InputFieldComponent
                                type="text"
                                :requiredLabel="true"
                                label="Your Website Address"
                                class="my-1 align-left w-full relative"
                                v-model.trim="form.landing_page_url"
                                inputClass="radius-r-0 p-3"
                                errorClass="absolute top-100p"
                                :content="`<span class='website-name border border-solid rounded border-gray-400 input-box-shadow mb-0 mt-1'>${this.domain}</span>`"
                                :message="($v.form.landing_page_url.$dirty && !$v.form.landing_page_url.required ? 'Field is required' : null) || formErrors.landing_page_url && formErrors.landing_page_url[0]"
                                :class="{'vuelidate-error': $v.form.landing_page_url.$error || formErrors.landing_page_url}"
                            />
                            
                        </div>
                    </div>
                </div>
                <div class="md-layout-item md-size-100">
                    <h2 class="m-2 p-4 mt-6 rounded-md d-bg-white mb-4">Address Info</h2>
                    <Address-form ref="address"/>
                </div>
                <div class="flex justify-end mt-6 mr-4">
                    <md-button class="bg-danger text-white rounded" @click="backToPrevious"> Back </md-button>
                    <Button
                      :disabled="disabled"
                      :loading="loading"
                      class="bg-victoria text-white rounded"
                      label="Submit"
                      type="submit"
                  >
                  </Button>
                </div>
            </form>
       </div>
       <Spinner :loading="isLoading" :diameter="40" :stroke="3" />
    </div>
  </template>
  
  <script>
  import { Button, InputFieldComponent, AddressForm, Spinner } from "@/components";
  import { required, email  } from "vuelidate/lib/validators";
  import { mapActions, mapState, mapMutations } from "vuex";
  import { scrollToFirstError } from "@/utils/scrollToFirstError";

  export default {
    components: {
        Button,
        InputFieldComponent,
        AddressForm,
        Spinner
    },  
    props: {
        componentType: {
          type: String,
          default: '',
        },
    },
    data() {
        return {
            loading: false,
            disabled: false,
            formErrors: {},
            form: {
                business_name: '',
                business_email: '',
                business_contact_number: '',
                landing_page_url: '',
                want_landing_page: 1,
                address_line_2: ''
            }
        };
    },
    computed: {
        ...mapState({
            isLoading: state => state.organization.isLoading,
        }),
        organizationId() {
            return this.$route.params.id
        },

        formMode() {
            return this.$route.params.id ? 'Edit' : 'Create'
        },
        validationRules() {
            return {
                business_name: { required },
                business_email: { required, email },
                business_contact_number: { required },
                landing_page_url: { required, validSubdomain: this.validSubdomain },
            };
        },
        domain() {
            const hostname = window.location.hostname; /*'superadmin.apical.io'*/
            const match = hostname.split('.');
            if (match.length > 2) {
                return match.slice(1).join('.'); /*'apical.io'*/
            }
            return hostname;
        }
    },
    methods: {
        ...mapMutations({
          setShowSnackbar: "setShowSnackbar",
        }),
        ...mapActions({
            actSaveOrganization: 'organization/actSaveOrganization',
            actGetOrganizationByOrganizationId: 'organization/actGetOrganizationByOrganizationId',
            actUpdateOrganization: 'organization/actUpdateOrganization'
        }),
        validSubdomain(value) {
            const subdomainPattern = /^(?!-)[a-zA-Z0-9-]{1,63}(?<!-)$/;
            return subdomainPattern.test(value);
        },
        backToPrevious() {
            this.$router.go(-1);
        },
        handleSubmit () {
            this.loading = true;
            this.$v.$touch();

            const isAddressValid = this.$refs.address.validateForm();
            if (this.$v.$invalid || isAddressValid) {
                this.loading = false;
                this.disabled = false;
                setTimeout(()=> {
                    scrollToFirstError()
                },10)
                return;
            }

            const { 
                address: business_full_address, 
                address2: address_line_2,
                country_id: business_country_id, 
                postal_code_id: business_postal_code_id
            } = this.$refs.address.getAddress();

            this.form = {
                ...this.form,
                business_full_address,
                address_line_2,
                business_country_id,
                business_postal_code_id
            };
            this.organizationId ? (
                this.actUpdateOrganization({
                    OrganizationId: this.organizationId,
                    payload: this.form
                })
                .then(() => {
                    this.$router.go(-1)
                    this.loading = false;
                }).catch(error => {
                    if (error.response) {
                      this.formErrors = error.response.data.details;
                    }
                    this.setShowSnackbar(error.response.data.message);
                    this.loading = false;
                })
            ):(
                this.actSaveOrganization(this.form)
                .then(response => {
                    console.log(response, 'response')
                    this.$router.go(-1)
                    this.loading = false;
                }).catch(error => {
                    if (error.response) {
                      this.formErrors = error.response.data.details;
                    }
                    this.setShowSnackbar(error.response.data.message);
                    this.loading = false;
                })
                .finally(() => this.loading = false)
            )
        },
        fetchUserData() {
            this.actGetOrganizationByOrganizationId(this.organizationId).then(organizationInfo => {
                this.form.business_name = organizationInfo.items.business_name,
                this.form.business_email = organizationInfo.items.business_email,
                this.form.business_contact_number = organizationInfo.items.business_contact_number,
                this.form.landing_page_url = organizationInfo.items.landing_page_url,
                this.form.want_landing_page = organizationInfo.items.want_landing_page
                const addressData = { 
                    address: organizationInfo.items.business_full_address, 
                    country_id: organizationInfo.items.business_country_id, 
                    postal_code_id: organizationInfo.items.business_postal_code_id
                } 
                this.$refs.address.setAddress(addressData);
            });
        },
    },
    watch: {
        'form.business_name'() {
          this.$v.form.business_name.$touch();
          if (this.formErrors.business_name) this.$delete(this.formErrors, 'business_name');
        },
        'form.business_email'() {
            this.$v.form.business_email.$touch();
            if (this.formErrors.business_email) this.$delete(this.formErrors, 'business_email');
        },
        'form.business_contact_number'() {
            this.$v.form.business_contact_number.$touch();
            if (this.formErrors.business_contact_number) this.$delete(this.formErrors, 'business_contact_number');
        },
        'form.landing_page_url'() {
            this.$v.form.landing_page_url.$touch();
            if (this.formErrors.landing_page_url) this.$delete(this.formErrors, 'landing_page_url');
        },
    }, 
    validations() {
        return {
            form: this.validationRules
        };
    },
    mounted() {    
       this.organizationId && this.fetchUserData()
    },
  }
  </script>
