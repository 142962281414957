<template>
  <div style="">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <InputFieldComponent
            label="Name of the Director/Owner"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.user.name.$model"
            :message="!$v.form.user.name.required && $v.form.user.name.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-50 mt-5">
        <InputFieldComponent
            label="Email Address"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.user.email.$model"
            :message="!$v.form.user.email.required && $v.form.user.email.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-50 mt-5">
        <InputFieldComponent
            label="Contact Number"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.user.contact_number.$model"
            :message="!$v.form.user.contact_number.required && $v.form.user.contact_number.$dirty ? 'Field is required' : null"
        />
      </div>
    </div>

    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>

import {
  Button,
  InputFieldComponent,
} from "@/components"
import { required } from "vuelidate/lib/validators";
import { email } from "vuelidate/lib/validators";
export default {
  name: "BasicInformation",

  components: {
    Button,
    InputFieldComponent,
  },

  data() {
    return {
      loading: false,

      form: {
        user: {
          active: 1,
          name: "",
          email: '',
          password: 123456,
          password_confirmation: 123456,
          type: 'COACHING_OWNER',
          contact_number: ''
        },
      }
    }
  },

  methods: {
 
    submit() {
   
      this.$v.$touch();
      if(this.$v.$invalid) return;

      let formData = this.getLocal('coaching-center-onboard');
      formData = {
        ...formData,
        ...this.form
      };
     
      this.setLocal('coaching-center-onboard', formData);

      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'coachingCenterAddress'});
    },

    clearAll(){
      this.$emit("clear-all", "");
    }
  },

  created() {
    let existData = this.getLocal('coaching-center-onboard');
    
    if (existData && existData.user) this.form.user = existData.user;
  },

  validations: {
    form: { 
      user: {
        name: {required},
        email: {required, email},
        contact_number: {required}
      }
    }
  }
}
</script>
