<template>
  <div style="margin-left: -10px">
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <InputFieldComponent
            label="Full Name"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.name.$model"
            :message="!$v.form.name.required && $v.form.name.$dirty ? 'Field is required' : null"
        />
      </div>
    </div>
    <div class="md-layout mt-4">
      <div class="md-layout-item md-size-50">
        <InputFieldComponent
            label="Email Address"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.email.$model"
            :message="!$v.form.email.required && $v.form.email.$dirty ? 'Field is required' : !$v.form.email.email && $v.form.email.$dirty ? 'Should be in email format' : null"
        />
      </div>
      <div class="md-layout-item md-size-50">
        <InputFieldComponent
            label="Contact Number"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.user_profile.contact_number.$model"
            :message="!$v.form.user_profile.contact_number.required && $v.form.user_profile.contact_number.$dirty ? 'Field is required' : null"
        />
      </div>
    </div>
        <AddressComponent 
        :isAddressOneShow="false" 
        :isAddressTwoShow="false" 
        :data="form"  
        ref="address" 
      />
    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Save & Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>

import {
  Button,
  InputFieldComponent,
  AddressComponent
} from "@/components"
import { required } from "vuelidate/lib/validators";
import { email } from "vuelidate/lib/validators";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "jobApplicationBasicInformation",

  components: {
    Button,
    InputFieldComponent,
    AddressComponent
  },

  data() {
    return {
      loading: false,
      form: {
        job_circular_id: '',
        user_id: '',
        email: '',
        name: '',
        type: 'TRAINER',
        active: 0, 
        user_profile: {
          contact_number: "",  
          country: '',
          country_id: '',
          your_country: '',
          your_country_id: '',
          postal_code_id: '',
          postal_code: '',
          state: '',
          city: ''
        },
        jobDetails: {},
        organization_id: [
            "hb53361b-06bf-495b-bddf-626fc3216310"
        ], 
      }
    }
  },
  computed: {
      ...mapGetters({
       jobDataFormat: "job/jobDataFormat",
      }),
  },

  methods: {
     ...mapActions({
      actCountryList: "registration/actCountryList",
      actPostalCodesByCountry: "registration/actPostalCodesByCountry",
      actGetJobByJobId: "job/actGetJobByJobId",
    }),
    

    submit() {

      console.log(this.form);
      if(this.$refs.address.checkValidation()) return;
      let address = this.$refs.address.$data.form;

      this.form.user_profile.country = address.country;
      this.form.user_profile.country_id = address.country_id;
      this.form.user_profile.your_country = address.your_country;
      this.form.user_profile.your_country_id = address.your_country_id;
      this.form.user_profile.postal_code_id = address.postal_code_id;
      this.form.user_profile.postal_code = address.postal_code;
      this.form.user_profile.state = address.state;
      this.form.user_profile.city = address.city;

      this.$v.$touch();
      if(this.$v.$invalid) return;

      let formData = this.getLocal('job-application-basic-info');
     
      console.log(formData);
      formData = {
        ...formData,
        ...this.form
      };

      this.setLocal('job-application-basic-info', formData);
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'jobProperties' });
    },

    clearAll(){

      this.form = {
        job_circular_id: '',
        user_id: '',
        email: '',
        name: '',
        type: 'TRAINER',
        active: 0, 
        user_profile: {
          contact_number: "",  
          country: '',
          country_id: '',
          your_country: '',
          your_country_id: '',
          postal_code_id: '',
          postal_code: '',
          state: '',
          city: ''
        },
        jobDetails: {},
        organization_id: [
            "hb53361b-06bf-495b-bddf-626fc3216310"
        ], 
      }
      let formData = this.getLocal('job-application-basic-info');
      console.log(formData);
      formData = {
        ...formData,
        ...this.form
      };

      this.$emit("menu", this.$route.name+'#false');
      this.setLocal('job-application-basic-info', formData);
    }
  },

  created() {

    this.form.job_circular_id = this.$route.params.jobCircularId;
    console.log(this.$route.params.jobCircularId)
    
    if(this.$route.params.jobCircularId){
      
      this.actGetJobByJobId(this.$route.params.jobCircularId).then(({items}) => {
        this.form.jobDetails = this.jobDataFormat(items);      
        this.setLocal('job-application-basic-info', this.form);      
      });
    }

    console.log(this.form);

    let existData = this.getLocal('job-application-basic-info');
    if(existData && existData.email) this.form = existData;
    
  },
  validations: {
    form: {
      name: {required},
      email: {required, email},
    
      user_profile: {
        contact_number: {required},
        country_id: {required},
      },
    }
  }
}
</script>
